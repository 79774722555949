import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { openpty } from "xterm-pty";
import "xterm/css/xterm.css";

const entry = async (id: string, loadJS: () => Promise<any>) => {
  const widthPixels = screen.width;

  const xterm = new Terminal({scrollback: 0, fontSize: Math.floor(widthPixels / 125), rows: 44});
  // xterm.write('\x1b[?47h');

  const div = document.getElementById(id + "-xterm");
  if (div) xterm.open(div);

  const { master, slave } = openpty();
  xterm.loadAddon(master);

  const fitAddon = new FitAddon();
  xterm.loadAddon(fitAddon);
  new ResizeObserver(() => fitAddon.fit()).observe(div);
  fitAddon.fit();

  const status = document.getElementById(id + "-status");

  const { default: initEmscripten } = await loadJS();
  module = await initEmscripten({
    pty: slave,
  });
  xterm.focus();
};

entry("example", () => import("../static/main.js"));
